import { useMemo } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Input,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const company_type = 'company_type';
const company_type_options = [
  'european_regulated_financial_institution',
  'non_european_regulated_financial_institution',
  'publicly_traded_company',
  'publicly_traded_company_on_other_market',
  'european_public_enterprise',
  'non_european_public_enterprise',
  'foundation_ngo_or_association',
  'other_private_company',
];
const other_regulated_market = 'other_regulated_market';

const validationSchema = Yup.object({
  [company_type]: Yup.array(Yup.mixed().oneOf(company_type_options))
    .min(1)
    .required()
    .label('This'),
  [other_regulated_market]: Yup.string().when(company_type, {
    is: (val: string[]) =>
      val.includes('publicly_traded_company_on_other_market'),
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const CompanyType = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { company } = useStore();

  const defaultValues = useMemo(() => {
    const default_company_type =
      company?.custom_properties?.[company_type] || [];
    const default_other_regulated_market =
      company?.custom_properties?.[other_regulated_market] || '';
    return {
      [company_type]: default_company_type,
      [other_regulated_market]: default_other_regulated_market,
    };
  }, [company?.custom_properties]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({
      companyData: {
        ...company,
        name: company?.name ?? null,
        country: company?.country ?? null,
        registration_number: company?.registration_number ?? null,
        ...company?.custom_properties,
        custom_properties: {
          [company_type]: formData[company_type],
          [other_regulated_market]: formData[other_regulated_market],
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="15" alignItems="start">
        <GroupController
          name={company_type}
          label={t(`steps.${stepId}.${company_type}.label`)}
          helper={t(`steps.${stepId}.${company_type}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <RadioGroup
              onChange={(value: string) => {
                resetField(other_regulated_market);
                setValue(company_type, [value] ?? [], {
                  // I set an array to be consistent with Dotfile Custom prop type choice
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              value={f.value[0]}
            >
              <VStack spacing="5" alignItems="start">
                <Box mt="5">
                  <Radio value="european_regulated_financial_institution">
                    {t(
                      `steps.${stepId}.${company_type}.options.european_regulated_financial_institution`,
                    )}
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6" fontSize="smaller">
                    <Text>
                      {t(
                        `steps.${stepId}.${company_type}.tooltips.european_regulated_financial_institution`,
                      )}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="non_european_regulated_financial_institution">
                    {t(
                      `steps.${stepId}.${company_type}.options.non_european_regulated_financial_institution`,
                    )}
                  </Radio>
                </Box>
                <Box>
                  <Radio value="publicly_traded_company">
                    {t(
                      `steps.${stepId}.${company_type}.options.publicly_traded_company`,
                    )}
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6" fontSize="smaller">
                    <Text>
                      {t(
                        `steps.${stepId}.${company_type}.tooltips.publicly_traded_company`,
                      )}
                      <a
                        href="https://www.amf-france.org/sites/institutionnel/files/private/2023-02/Liste%20des%20march%C3%A9s%20reconnus%20au%20310123.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t(
                          `steps.${stepId}.${company_type}.tooltips.publicly_traded_company_link_text`,
                        )}
                      </a>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="publicly_traded_company_on_other_market">
                    {t(
                      `steps.${stepId}.${company_type}.options.publicly_traded_company_on_other_market`,
                    )}
                  </Radio>
                </Box>
                <Box>
                  <Radio value="european_public_enterprise">
                    {t(
                      `steps.${stepId}.${company_type}.options.european_public_enterprise`,
                    )}
                  </Radio>
                </Box>
                <Box>
                  <Radio value="non_european_public_enterprise">
                    {t(
                      `steps.${stepId}.${company_type}.options.non_european_public_enterprise`,
                    )}
                  </Radio>
                </Box>
                <Box>
                  <Radio value="foundation_ngo_or_association">
                    {t(
                      `steps.${stepId}.${company_type}.options.foundation_ngo_or_association`,
                    )}
                  </Radio>
                </Box>
                <Box>
                  <Radio value="other_private_company">
                    {t(
                      `steps.${stepId}.${company_type}.options.other_private_company`,
                    )}
                  </Radio>
                </Box>
              </VStack>
            </RadioGroup>
          )}
        />

        {watch(company_type).includes(
          'publicly_traded_company_on_other_market',
        ) && (
          <GroupController
            name={other_regulated_market}
            label={t(`steps.${stepId}.${other_regulated_market}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
