import { Helmet } from 'react-helmet-async';
import { Environment } from 'shared-domain';

type CSPHeadersProps = {
  environment: Environment;
  nonce: string;
  extraConnectSrc?: string;
  extraScriptSrc?: string;
  extraStyleSrc?: string;
  extraObjectSrc?: string;
  allowBlob?: boolean;
  allowDatadogRUM?: boolean;
  allowUbble?: boolean;
};

/**
 * CSP Headers for modern browser security
 * @doc https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP
 * @doc https://cheatsheetseries.owasp.org/cheatsheets/Content_Security_Policy_Cheat_Sheet.html
 * @doc https://csp-evaluator.withgoogle.com/
 * @note need to allow pdfjs to load, this should be package with the App @TODO - E-208
 *
 * CONSIDERATION with helmet: CSP are loaded when the main.js is loaded since it is managed by Helmet.
 * Thus the main.js script is injected and loaded and the strict-dynamic policy apply.
 * Since main.js is loaded, with strict-dynamic, every script loaded by main.js are authorized.
 * Also strict-dynamic is only for CSPv3 so to be retro-compatible for older browser version, we also put host whitelist.
 *
 * Script
 * - strict-dynamic: https://content-security-policy.com/strict-dynamic/
 * - PDFjs
 * - unsafe-inline is required and ignored by browsers supporting nonces/hashes to be backward compatible with older browsers
 *
 * Worker/Child
 * - PDFjs create some blob script in the web worker using blob:, that's why we need to enable them
 *
 * Connect:
 * - Enable Rudderstack DataPlane
 * - Enable GraphQL API
 * - Enable Datadog RUM (+ bugsnag internally use by datadog RUM)
 *
 * Style:
 * - Style nonce using emotion cache see: https://github.com/chakra-ui/chakra-ui/issues/3294
 * - We also need to pass the sha256 of the inline style for the base app loader in the index.html (static 'sha256-Q61bDQPgXhXJM0KRBMX+u6YXfcEtPAxqo0SHvNG3uoU=')
 * - /!\ If we change the loader style we will need to change it here (we don't this very often)
 * - Use 'unsafe-inlife' for inline style in translation (temporary fix March 26th 2024)
 *
 * Object:
 * - Use to display PDF in libs/frontend/components/src/lib/steps/pdf-viewer.tsx
 * - Should whitelist all PDF URL
 * - Also need to add them in frame-src for chromium browser
 *
 * Others:
 * require-trusted-types-for 'script'; -> not working with Google Auth, no workaround
 * https://github.com/w3c/trusted-types
 * https://github.com/w3c/trusted-types/blob/main/explainer.md
 */
export const CSPHeaders = ({
  environment,
  nonce,
  extraConnectSrc,
  extraScriptSrc,
  extraStyleSrc,
  extraObjectSrc,
  allowBlob,
  allowDatadogRUM,
  allowUbble,
}: CSPHeadersProps) => {
  return (
    <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={`
                child-src 'self' ${environment.baseUrlApp} ${
                  allowBlob && 'blob:'
                };
                worker-src 'self' ${environment.baseUrlApp} ${
                  allowBlob && 'blob:'
                };
                frame-src ${environment.baseUrlApp} ${
                  allowUbble && 'https://id.ubble.ai'
                } ${extraObjectSrc};
                connect-src 'self' ${environment.baseUrlApi} ${
                  allowDatadogRUM &&
                  'https://browser-intake-datadoghq.eu https://sessions.bugsnag.com'
                } ${extraConnectSrc};
                script-src 'nonce-${nonce}' 'strict-dynamic' ${
                  environment.baseUrlApp
                } ${extraScriptSrc} 'unsafe-inline';
                style-src 'nonce-${nonce}' ${
                  environment.baseUrlApp
                } ${extraStyleSrc} 'unsafe-inline';
                font-src 'self' ${environment.baseUrlApp};
                object-src 'self' ${environment.baseUrlApp} ${extraObjectSrc};
                base-uri 'none';
                media-src 'none';
            `
          .replace(/undefined/g, '')
          .replace(/\s+/g, ' ')} // Single whitespace cleaning
      ></meta>
    </Helmet>
  );
};
