const brandColors = {
  primary: '#1C1C1C',
  secondary: '#C5A1FE',
};

const sidebarColors = {
  backgroundColor: '#000000',
  color: '#FFFFFF',
  dotfileLogo: '#FFFFFF',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};

// const brandColors = {
//   primary: '#1C1C1C',
//   secondary: '#C5A1FE',
// };

// const sidebarColors = {
//   backgroundColor: '#E7D8FF',
//   color: '#1C1C1C',
//   dotfileLogo: '#000000',
// };

// const linkColors = {
//   color: '#0176FF',
// };

// export const tokens = {
//   brand: brandColors,
//   sidebar: sidebarColors,
//   link: linkColors,
// };
