import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { CompanyType } from '../app/components/company-type';

export const stepsConfig: StepConfig[] = [
  {
    id: 'onboarder_data',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'onboarder_first_name',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'onboarder_last_name',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'onboarder_date_of_birth',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.date,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'onboarder_place_of_birth',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'onboarder_role_or_position',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'company_type',
    type: StepTypeEnum.custom_component,
    component: CompanyType,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'alternative_business_address',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.textarea,
          hasPlaceholder: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'company_bank_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'iban',
          nested: 'banking_information',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.iban,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.bic,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true, hasDelegator: true },
  },
];
